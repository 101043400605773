import React from 'react';
import spiderIcon from '../../assets/images/spider.png'
import { motion, AnimatePresence } from 'framer-motion';
import './divider.css';

const Divider: React.FC = ({text,cssClass}) => {
    const variants = {
        initial: {
            opacity: 0,
            x:300
        },
        enter: {
            opacity: 1,
            x:10,
            transition: {
                x: { type: "spring", stiffness: 20 },
                opacity: { duration: 0.5 }
            },
        },
        exit:{},
        pageWorkAnimate:{}
    };
    const variantsRight = {
        initial: {
            opacity: 0,
            x:-300
        },
        enter: {
            opacity: 1,
            x:-10,
            transition: {
                x: { type: "spring", stiffness: 20 },
                opacity: { duration: 0.5 }
            },
        },
        exit:{},
        pageWorkAnimate:{}
    };  

    return (
        <div className={cssClass+" page-divider"}>
            <div className="d-flex justify-content-between">
                <div className="align-self-center p-2">
                    <motion.img variants={variants} initial="initial" animate="enter" src={spiderIcon} alt="Ícone logo teia" />
                </div>
                <div className="align-self-center">
                    <h1>{text}</h1>
                </div>
                <div className="align-self-center p-2">
                    <motion.img variants={variantsRight} initial="initial" animate="enter" src={spiderIcon} alt="Ícone logo teia" />
                </div>
            </div>
        </div>
    );
}

export default Divider;