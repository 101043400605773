import React from 'react'
import Layout from '../Layout';
import Divider from '../Divider';
import Img from 'gatsby-image';
import { Carousel } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "gatsby";
//import ReactModal from 'react-modal';
import './project.css';

const container = {
    initial: {
        opacity: 1,
    },
    enter: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
    }
};

const animatedItem = {
  initial: { y: 20, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition:{
      opacity:{ duration: 1 }
    }
  },
};

const variantsPortfolio = {
  initial:{
    x:-20,
    opacity:0,
  },
  enter: {
    x:0,
    opacity:1,
    transition: {
      x: { type: "spring", stiffness: 50},
      opacity: { duration: 1 }
    },
  }
};

const variantsImg = {
  active: {
    scale:4, 
    transition:{
      scale:{ease:'linear',duration:1}
    }
  },
  inactive: { 
    scale:1, 
    transition:{
      scale:{ease:'linear',duration:0.5}
    }
  }
}

export const Portfolio =({project}) =>{
  const [isActive, setIsActive] = React.useState(false);
  return(
    <motion.img src={project.childImageSharp.fluid.src}
      className="d-inline-block align-top rounded-circle img-fluid project-portfolio m-3"
      alt="Portfolio"
      variants={variantsImg}
      animate={isActive ? 'active' : 'inactive'}
      onClick={ ()=> setIsActive(!isActive) }/>);
}

export const ProjectChild = ({project}) => {  
    return (
      <React.Fragment>
        <Divider text="NOSSO TRABALHO"/>
          <motion.div id="page-work" variants={container} initial="initial" animate="enter" exit="exit">
            <div className="container-fluid h-100">
              <div className="d-flex flex-row justify-content-between header">
                <motion.div className="col-lg-3 col-md-3 col-sm-3" variants={variantsPortfolio}>
                  <Link
                    className="carousel-control-prev"
                    to={`/projetos/${project.prev}`}>
                    <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                    <span className="sr-only">Anterior</span>
                  </Link>
                </motion.div>
                <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                  <motion.h1 variants={animatedItem} className="display-4">
                    {project.title}
                  </motion.h1>
                </div>
                <motion.div className="col-lg-3 col-md-3 col-sm-3" variants={variantsPortfolio}>
                  <Link
                    className="carousel-control-next"
                    to={`/projetos/${project.next}`}>
                    <span aria-hidden="true" className="carousel-control-next-icon"></span>
                    <span className="sr-only">Próximo</span>
                  </Link>
                </motion.div>
              </div>
              <div className="d-flex flex-row">
                <div className="col-md-12 p-0 m-0">
                  <motion.div className="container text-center mt-5 mb-5" variants={variantsPortfolio}>
                    <Link
                      to={`${project.midiaPageUrl}`}>
                        {project.midia.length > 0 && <Portfolio project={project.midia[0]}/>}
                      </Link>
                  </motion.div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <motion.div className="col-sm-12 col-md-8 col-lg-6">
                  <div className="container text-wrap" id="project-content">
                    {<motion.div variants={animatedItem} dangerouslySetInnerHTML= {{ __html: project.content}}></motion.div>}
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
      </React.Fragment>
    )
}

const Project = ({pageContext: project,...props}) => {
  return (<ProjectChild project={project} />);
}
 
export default Project